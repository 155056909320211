<template>
  <!-- 设置申请表 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 头部面包屑 -->
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">基础信息</a>
          <i>></i>
          <a href="javascript:;">评价工种维护</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">设置申请表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">{{ lastPageData.applyConfigId ? '编辑申请表' : '设置申请表' }}</a>
        </span>
      </div>
      <!-- 主体区域 -->
      <div class="framePage-body">
        <el-container>
          <el-main>
            <h3>内容设置</h3>
            <el-form ref="setDsta_1" class="setDsta_1" :rules="setDsta_1.setDsta_1Rules" :model="setDsta_1" size="small"
              label-width="100px">
              <el-form-item label="培训工种">
                {{ lastPageData.occupationName }}
              </el-form-item>
              <el-form-item label="培训等级">
                {{ $setDictionary("ET_TRAIN_LEVEL", lastPageData.trainLevel) }}
              </el-form-item>
              <el-form-item label="申请表模板" prop="applyTemplateId">
                <el-select clearable placeholder="请选择申请表模板" v-model="setDsta_1.applyTemplateId"
                  @change="setAssociatedData">
                  <el-option v-for="item in setDsta_1.templateList" :key="item.applyTemplateId"
                    :label="item.applyTemplateName" :value="item.applyTemplateId">
                  </el-option>
                </el-select>
                <el-button type="text" size="mini" @click="previewOutside">预览</el-button>
              </el-form-item>
            </el-form>
            <h3>申请表信息项</h3>
            <el-table ref="multipleTable" :data="setDsta_2.userFieldBOS" :height="tableHeight" size="small"
              tooltip-effect="dark" style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="50" />
              <el-table-column label="报表中列名称" align="center" min-width="200">
                <template v-slot="{ row }">
                  {{ row.fieldName }}
                </template>
              </el-table-column>
              <el-table-column label="招生信息中信息项" align="center" min-width="100">
                <template v-slot="{ row }">
                  <el-select :class="row.attributeId ? '' : 'sss'" clearable placeholder="请选择信息项" v-model="row.attributeId"
                    size="small">
                    <el-option v-if="row.groupKey === item.groupKey" v-for="item in setDsta_2.attributeBOS"
                      :key="item.attributeId" :label="item.attributeLabel" :value="item.attributeId">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
            <h3>申请表内容设置</h3>
            <el-form ref="setDsta_3" class="setDsta_3" :rules="setDsta_3.setDsta_3Rules" :model="setDsta_3" size="small"
              label-width="150px">
              <el-form-item :label="item.fieldName" v-for="(item, index) in setDsta_3.defaultFieldBOS" :key="index">
                <el-input type="textarea" placeholder="请输入内容" v-model="item.fieldValue" maxlength="2000">
                </el-input>
              </el-form-item>
            </el-form>
          </el-main>
          <el-footer style="text-align:center">
            <el-button class="customBtn" round @click="$router.back()">取 消</el-button>
            <el-button class="customBtn" round type="primary" @click="submit">保 存</el-button>
          </el-footer>
        </el-container>
      </div>
    </div>
    <!-- 预览 -->
    <el-dialog title="预览" :visible.sync="previewData.dialogTableVisible" :modal="false" width="50%" top="2%" center>
      <div id="pdf-cert1" style="height: 700px"></div>
    </el-dialog>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
import pdf from "pdfobject";
export default {
  name: "evaluationMaintenance",
  components: {
    Empty,
  },
  data() {
    return {
      // 上个页面传过来的数据
      lastPageData: {
        occupationId: "",//工种id
        occupationName: "", // 培训工种名称
        trainLevel: "", // 培训等级
        applyConfigId: "", // 有这个id就是编辑过得(没啥用，就一个标识)
      },
      // 内容设置
      setDsta_1: {
        applyTemplateId: "", // 模板id
        templateList: [], // 模板下拉数据
        // 数据校验
        setDsta_1Rules: {
          applyTemplateId: [
            { required: true, message: "请选择档案模板", trigger: "change" },
          ],
        }
      },
      // 申请表信息
      setDsta_2: {
        userFieldBOS: [], // 用户属性列表
        attributeBOS: [], // 报名属性列表
      },
      // 申请表内容设置
      setDsta_3: {
        defaultFieldBOS: [], // 申请表内容设置
      },
      // 预览
      previewData: {
        dialogTableVisible: false, // 弹框显示隐藏
      }
    }
  },
  mixins: [List],
  created() {
    this.lastPageData.occupationId = this.$route.query.occupationId || "";//工种id
    this.lastPageData.occupationName = this.$route.query.occupationName || "";// 培训工种名称
    this.lastPageData.trainLevel = this.$route.query.trainLevel || "";// 培训等级
    this.lastPageData.applyConfigId = this.$route.query.applyConfigId || "";// 有这个id就是编辑过得(没啥用，就一个标识)
    if (this.$route.query.occupationId) {
      this.getConfigurationData(this.$route.query.occupationId);
    }
  },
  mounted() { },
  computed: {

  },
  methods: {
    // 获取配置信息
    getConfigurationData(occupationId) {
      this.$post("/biz/apply_template/ct-apply-form-config/getSetting", {
        occupationId
      }, 3000, true, 6).then(res => {
        // 处理：内容设置数据
        for (let i = 0; i < res.data.templateList.length; i++) {
          if (res.data.templateList[i].checked) {
            this.setDsta_1.applyTemplateId = res.data.templateList[i].applyTemplateId
          }
        }
        this.setDsta_1.templateList = res.data.templateList;
        // 处理：申请表信息
        this.setDsta_2.userFieldBOS = res.data.userFieldBOS;
        this.setDsta_2.attributeBOS = res.data.attributeBOS;
        // 处理：申请表内容设置
        this.setDsta_3.defaultFieldBOS = res.data.defaultFieldBOS;
      });
    },
    // 预览
    previewOutside() {
      if (this.setDsta_1.applyTemplateId) {
        let ylSrc = '';
        for (let i = 0; i < this.setDsta_1.templateList.length; i++) {
          if (this.setDsta_1.applyTemplateId == this.setDsta_1.templateList[i].applyTemplateId) {
            ylSrc = this.setDsta_1.templateList[i].sampleKey;
          }
        }
        this.previewData.dialogTableVisible = true;
        this.$nextTick(() => {
          pdf.embed(ylSrc, "#pdf-cert1");
        })
      } else {
        this.$message.warning('没有要预览的档案模板');
      }
    },
    // 更改模板 - 获取模板信息关联数据
    setAssociatedData(val) {
      if (val) {
        this.$post("/biz/apply_template/ct-apply-form-config/getTemplateInfo", {
          occupationId: this.lastPageData.occupationId,
          applyTemplateId: val
        }, 3000, true, 6).then(res => {
          console.log(res)
          // 处理：内容设置数据
          this.setDsta_2.userFieldBOS = res.data.userFieldBOS;
          // 处理：申请表内容设置
          this.setDsta_3.defaultFieldBOS = res.data.defaultFieldBOS;
        })
      }
    },
    // 表单提交
    submit() {
      this.$refs.setDsta_1.validate(valid => {
        if (valid) {
          let ttt = true;
          this.setDsta_2.userFieldBOS.forEach(element => {
            if (!element.attributeId) {
              this.$message.warning('申请表信息存在未选项');
              ttt = false;
              throw new Error();
            }
          });
          if (ttt) {
            let cData = {
              occupationId: this.lastPageData.occupationId,
              applyTemplateId: this.setDsta_1.applyTemplateId,
              userFieldBOS: this.setDsta_2.userFieldBOS,
              defaultFieldBOS: this.setDsta_3.defaultFieldBOS,
            }
            this.$post("/biz/apply_template/ct-apply-form-config/insert", cData, 3000, true, 6).then(res => {
              if (this.lastPageData.applyConfigId) {
                this.$message.success('报表内容更改后，原报名申请表已作废，请重新下载');
                setTimeout(() => {
                  this.$router.back();
                }, 1000)
              } else {
                this.$message.success('设置成功');
                this.$router.back();
              }
            });
          }
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>
.el-main {
  padding: 0 20px;

  h3 {
    margin-bottom: 20px;
  }

  /deep/ .sss {
    .el-input {
      input {
        border-color: red !important;
      }
    }
  }

  .setDsta_1 {
    width: 600px;
    margin-bottom: 20px;

    .el-form-item {
      margin-bottom: 0;

      /deep/ .el-select {
        width: calc(100% - 40px);
        float: left;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .el-table {
    margin-bottom: 20px;
  }

  .setDsta_3 {
    .el-form-item {

      width: 50%;
      float: left;

      /deep/ textarea {
        height: 100px;
      }
    }
  }
}
</style>